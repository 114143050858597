import { forwardRef, useRef, useState } from "react";
import { Anchor, Box, Drop, Text } from "grommet";
import {
  Achievement,
  FormDown,
  Secure,
  Shield,
  Microphone,
  Configure,
  Article,
  Send,
  // MoreVertical,
} from "grommet-icons";
import Link from "next/link";
// import Image from "next/legacy/image";
import { useRouter } from "next/router";
import styled from "styled-components";
import useIsLarge from "@cybertools/ui/es/hooks/useIsLarge";
import RelativeBox from "@components/relativeBox";
// import { usePageValue } from "@organisms/page/provider";
// import logoLeanSvg from "public/assets/images/logo-lean.svg";
import Dropdown, { DropdownLink } from "./dropdown";

const StyledAnchor = styled(Anchor).attrs({
  weight: "normal",
  size: "small",
  gap: "xsmall",
})`
  padding: ${({ isLarge }) => (isLarge ? "24px" : "16px")};
  white-space: nowrap;

  ${({ active, theme }) =>
    active &&
    `
  border-bottom: 2px solid ${theme.global.colors.brand};
  `}

  &:hover {
    ${({ theme }) => `
      color: ${theme.global.colors.brand};
      svg {
        fill: ${theme.global.colors.brand};
        stroke: ${theme.global.colors.brand};
      }
      `};
  }
`;

// eslint-disable-next-line react/display-name
export const LinkAnchor = forwardRef((props, ref) => {
  const isLarge = useIsLarge();
  const router = useRouter();
  const { href } = props;

  const active =
    href === "/" ? router.pathname === "/" : router.pathname.startsWith(href);

  return (
    <StyledAnchor
      role="menuitem"
      tabindex="-1"
      active={active}
      isLarge={isLarge}
      ref={ref}
      {...props}
    />
  );
});

export const Ul = styled(Box).attrs({
  margin: "none",
  pad: "none",
  tag: "ul",
  direction: "row",
  align: "center",
})`
  li:after {
    content: "•";
    align-self: center;
  }
  li {
    list-style: none;
    display: flex;
    flex-direction: row;
  }
  li:last-child:after {
    content: none;
  }
`;

const TagContainer = styled(Box).attrs({
  background: "accent-4",
  round: "medium",
  pad: { horizontal: "6px", vertical: "0" },
  justify: "center",
})`
  position: absolute;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
`;

function NavLink({ href, newLabel, ...rest }) {
  return (
    <Box as="li" role="none" flex={false}>
      <Link href={href} passHref legacyBehavior>
        <RelativeBox direction="row" align="center">
          <LinkAnchor {...rest} />
          {newLabel && <Tag />}
        </RelativeBox>
      </Link>
    </Box>
  );
}

function Tag() {
  const large = useIsLarge();

  return (
    <TagContainer
      margin={{
        left: "56px",
        bottom: large ? "38px" : "30px",
      }}
    >
      <Text size={large ? "small" : "xsmall"}>New</Text>
    </TagContainer>
  );
}

const StyledDown = styled(FormDown)`
  ${({ up }) => up && `transform: rotate(180deg);`}
`;

function NavDropdown({ children, href, label, newLabel, ...rest }) {
  const large = useIsLarge();
  const ref = useRef();
  const [open, setOpen] = useState(false);

  href = large ? href : "#";
  const isButton = href === "#";

  return (
    <Box
      as="li"
      role="none"
      {...(large && {
        onMouseEnter: () => setOpen(true),
        onMouseLeave: () => setOpen(false),
      })}
      onClick={() => setOpen((s) => !s)}
      ref={ref}
      flex={false}
    >
      <Link href={href} passHref legacyBehavior>
        <LinkAnchor
          label={
            <RelativeBox direction="row" align="center">
              <Text margin={{ right: "xsmall" }} size="small">
                {label}
              </Text>
              <StyledDown size="16px" up={open ? 1 : undefined} />
              {newLabel && <Tag />}
            </RelativeBox>
          }
          {...(isButton && { onClick: (e) => e.preventDefault() })}
          {...rest}
        />
      </Link>
      {ref.current && (
        <Drop
          style={{ display: open ? "" : "none" }}
          align={{ top: "bottom", left: "left" }}
          target={ref.current}
          onClickOutside={
            large || !open ? null : () => setTimeout(() => setOpen(false), 10)
          }
          plain
        >
          <Dropdown>{children}</Dropdown>
        </Drop>
      )}
    </Box>
  );
}

export default function Navigation(props) {
  const large = useIsLarge();
  // const pwa = usePageValue("pwa");

  return (
    <Box
      as="nav"
      role="navigation"
      width={{ width: "100%", max: "xxlarge" }}
      align="center"
      {...props}
    >
      <Ul overflow={{ horizontal: "auto" }}>
        {large && (
          <NavLink
            href="/"
            label="Today's best"
            icon={<Achievement size="16px" />}
            data-ga="navigation::click::todaysBest"
          />
        )}
        <NavDropdown
          href="/airdrop"
          label="Airdrop"
          icon={<Send size="16px" />}
          data-ga="navigation::click::audit"
        >
          <DropdownLink
            href="/airdrop/active"
            label="Airdrop List"
            data-ga="navigation::click::airdrop-list"
          />
          <DropdownLink
            href="/requestairdrop"
            label="Create Airdrop"
            data-ga="navigation::click::airdrop-create"
          />
        </NavDropdown>
        <NavLink
          href="/audit"
          label="Audit"
          icon={<Secure size="16px" />}
          data-ga="navigation::click::audit"
        />
        <NavLink
          href="/kyc"
          label="KYC"
          icon={<Shield size="16px" />}
          data-ga="navigation::click::kyc"
        />
        <NavLink
          href="/ama"
          label="AMA"
          icon={<Microphone size="16px" />}
          data-ga="navigation::click::ama"
        />
        <NavDropdown
          href="#"
          label="Contract Tools"
          icon={<Configure size="16px" />}
          data-ga="navigation::click::contractTools"
          newLabel
        >
          <DropdownLink
            href="/createtoken"
            label={<Text>Create Token</Text>}
            data-ga="navigation::click::createToken"
          />
          <DropdownLink
            href="/lockers/tokens"
            label={<Text>Lockers</Text>}
            data-ga="navigation::click::lockers"
          />
        </NavDropdown>
        <NavLink
          href="/blog"
          label="Blog"
          icon={<Article size="16px" />}
          data-ga="navigation::click::blog"
        />
        {/* <NavLink
          href="/token"
          label="Coinscope Token"
          icon={
            <Image
              src={logoLeanSvg}
              width="16px"
              height="16px"
              alt="Coinscope Token"
            />
          }
          data-ga="navigation::click::token"
        /> */}

        {/* <NavDropdown href="#" label="More" icon={<MoreVertical size="16px" />}>
          <DropdownLink
            label="Earn"
            href="/earn"
            data-ga="navigation::click::earn"
          />
          <DropdownLink
            label="Escrow"
            href="/escrow"
            data-ga="navigation::click::escrow"
          />
          {!pwa && (
            <DropdownLink
              href="/nft/home"
              label="NFT"
              data-ga="navigation::click::nft-home"
            />
          )}
        </NavDropdown> */}
      </Ul>
    </Box>
  );
}
